import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { differenceInMinutes, parseISO } from 'date-fns';

import { FiChevronLeft, FiChevronDown, FiChevronRight } from 'react-icons/fi';
import placeholderImg from '../../assets/imenta-placeholder.svg';

import api from '../../services/api';

import {
  Container,
  TitleContainer,
  PageTitle,
  EmentLists,
  EmentListActive,
  AllEmentList,
  EmentListItens,
  ProductInformation,
  ProductinformationPrices,
  BackgroundFilter,
  SubCategoriesList,
} from './styles';

interface EmentProduct {
  id: string;
  image?: string;
  title: string;
  description: string;
  dose?: string;
  meiaDose?: string;
  price_in_doses: boolean;
  is_drink: boolean;
  capacity: string;
  capacity_unit: string;
}
interface Ement {
  title: string;
  url: string;
  opcoes: string;
  type: string;
  products?: Array<EmentProduct>;
  subcategory?: Array<Ement>;
}

interface Company {
  logo: string;
  name: string;
}

interface Translation {
  [key: string]: string;
}

interface Response {
  languages: Array<Language>;
  ement: Array<Ement>;
  company: Company;
  translations: Translation;
}

interface Language {
  id: string;
  lc_time_code: string;
  name: string;
  flag: string;
}

const EmentList: React.FC = () => {
  const history = useHistory();
  const [filterVisible, setFilterVisible] = useState(false);
  const [products, setProducts] = useState<EmentProduct[]>([]);
  const [ements, setEments] = useState<Ement[]>([]);
  const [translations, setTranslations] = useState<Translation>({});
  const [subCategories, setSubCategories] = useState<Ement[]>([]);
  const [activeEment, setActiveEment] = useState<string | undefined>('');
  const { ement } = useParams();

  const handleFilterClick = (): void => {
    setFilterVisible(!filterVisible);
  };

  const handlerGoBack = (): void => {
    history.goBack();
  };

  async function getData(sendLanguage: string): Promise<Response> {
    const response = await api.get<Response>(
      `admin/${sendLanguage}/api/get-data`,
    );

    return response.data;
  }

  useEffect(() => {
    const setData = async (response: Response): Promise<void> => {
      let customResponse: Response;
      customResponse = response;
      if (response.ement === undefined) {
        const language = localStorage.getItem('language') || 'pt';
        customResponse = await getData(language);
      }
      localStorage.setItem('apiData', JSON.stringify(customResponse));

      try {
        let activeCategory = customResponse.ement.find(
          (category: Ement) => category.url === ement,
        );
        if (!activeCategory) {
          const activeSubCategory = customResponse.ement.find(
            (category: Ement) => {
              const subcategory = category.subcategory?.find(
                (subCategory: Ement) => subCategory.url === ement,
              );
              if (subcategory) {
                return subcategory;
              }
            },
          );
          activeCategory = activeSubCategory?.subcategory?.find(
            (subCategory: Ement) => subCategory.url === ement,
          );
        }

        setActiveEment(activeCategory?.title);

        const activeProducts = activeCategory?.products;

        // const newCategories = response.ement;

        setEments(response.ement);
        setProducts(activeProducts || []);
        setTranslations(response.translations);
        setSubCategories(activeCategory?.subcategory || []);
      } catch (error) {
        console.log(error);
      }
    };

    async function getProducts(): Promise<void> {
      const language = localStorage.getItem('language') || 'pt';
      const lastLoadedDataDate = localStorage.getItem('lastUpdate');

      let response: Response = {
        ement: [],
        languages: [],
        company: { logo: '', name: '' },
        translations: {},
      };

      if (lastLoadedDataDate) {
        if (
          differenceInMinutes(parseISO(lastLoadedDataDate), new Date()) < -5
        ) {
          localStorage.setItem('lastUpdate', new Date().toISOString());
          response = await getData(language);
        } else if (localStorage.getItem('apiData')) {
          response = JSON.parse(localStorage.getItem('apiData') || '');
        }
      } else {
        localStorage.setItem('lastUpdate', new Date().toISOString());
        response = await getData(language);
      }

      setData(response);
    }

    setFilterVisible(false);
    getProducts();
  }, [ement]);

  return (
    <Container>
      <TitleContainer>
        <PageTitle>
          <button type="button" onClick={handlerGoBack}>
            <FiChevronLeft size={20} color="#0029ff" />
          </button>
          <h2>iMenta - Restaurante</h2>
        </PageTitle>
        <EmentLists>
          <EmentListActive onClick={handleFilterClick} visible={filterVisible}>
            <p>{activeEment}</p>
            <div>
              <FiChevronDown size={20} color="#0029ff" />
            </div>
          </EmentListActive>
          <AllEmentList visible={filterVisible}>
            {ements
              .filter((ementItem) => ementItem.url !== ement)
              .map((ementItem) => {
                return (
                  <Link key={ementItem.url} to={`/ement/${ementItem.url}`}>
                    {ementItem.title}
                  </Link>
                );
              })}
          </AllEmentList>
        </EmentLists>
        <BackgroundFilter onClick={handleFilterClick} visible={filterVisible} />
      </TitleContainer>
      {subCategories.length && (
        <SubCategoriesList>
          {subCategories.map((subCategory) => (
            <Link to={`/ement/${subCategory.url}`}>
              {subCategory.title}
              <FiChevronRight size={20} color="#0029ff" />
            </Link>
          ))}
        </SubCategoriesList>
      )}
      <EmentListItens>
        {products.map((product) => (
          <div key={product.id}>
            {product.image && (
              <Zoom>
                <LazyLoadImage
                  width="107"
                  effect="opacity"
                  height="112"
                  alt={product.title}
                  src={product.image}
                  placeholderSrc={placeholderImg}
                />
              </Zoom>
            )}
            <ProductInformation>
              <h2>{product.title}</h2>
              <p> {product.description} </p>
              <ProductinformationPrices>
                {product.dose && !product.is_drink && (
                  <div>
                    <p>{product.dose} €</p>
                    <span>
                      {product.price_in_doses && translations.one_dose}
                    </span>
                  </div>
                )}
                {product.meiaDose && !product.is_drink && (
                  <div>
                    <p>{product.meiaDose} €</p>
                    <span>
                      {product.price_in_doses && translations.half_dose}
                    </span>
                  </div>
                )}
                {product.is_drink && (
                  <div>
                    <p>{product.dose} €</p>
                    <span>
                      {product.capacity} {product.capacity_unit}
                    </span>
                  </div>
                )}
              </ProductinformationPrices>
            </ProductInformation>
          </div>
        ))}
      </EmentListItens>
    </Container>
  );
};

export default EmentList;
