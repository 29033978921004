import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { differenceInMinutes, parseISO } from 'date-fns';

import api from '../../services/api';

import {
  Container,
  EmentCategoryList,
  ListLoading,
  Settings,
  LanguagesContainer,
  CloseContainer,
} from './styles';

import logoImg from '../../assets/logo.svg';

interface Category {
  title: string;
  url: string;
  opcoes: string;
  type: string;
}

interface Company {
  logo: string;
  name: string;
}

interface Translation {
  [key: string]: string;
}

interface Response {
  languages: Array<Language>;
  ement: Array<Category>;
  company: Company;
  translations: Translation;
}

interface Language {
  id: string;
  lc_time_code: string;
  name: string;
  flag: string;
}

const HomePage: React.FC = () => {
  const [logo, setLogo] = useState(logoImg);
  const [language, setLanguage] = useState('pt');
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [translations, setTranslations] = useState<Translation>({});
  const [categories, setCategories] = useState<Category[]>([]);
  const [languagesActive, setLanguagesActive] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState<Language>();

  const handleLanguageClick = (): void => {
    setLanguagesActive(!languagesActive);
  };

  async function getData(sendLanguage: string): Promise<Response> {
    const response = await api.get<Response>(
      `admin/${sendLanguage}/api/get-data`,
    );

    return response.data;
  }

  const setData = useCallback(async (response: Response) => {
    localStorage.setItem('apiData', JSON.stringify(response));
    setLogo(response.company.logo);
    setCategories(response.ement);
    setLanguages(response.languages);
    setTranslations(response.translations);
    setLoading(false);
    const selectedLanguage = localStorage.getItem('language') || 'pt';
    const activedLanguage = response.languages.find(
      (languageItem) => languageItem.id === selectedLanguage,
    );
    setActiveLanguage(activedLanguage);
  }, []);

  const handleChangeLocation = async (
    selectedLanguage: string,
  ): Promise<void> => {
    localStorage.setItem('language', selectedLanguage);
    setLanguage(selectedLanguage);
    setLanguagesActive(!languagesActive);
    const response = await getData(selectedLanguage);
    setData(response);
  };

  useEffect(() => {
    async function getCategories(): Promise<void> {
      const lastLoadedDataDate = localStorage.getItem('lastUpdate');
      const selectedLanguage = localStorage.getItem('language') || 'pt';
      setLanguage(selectedLanguage);

      let response: Response = {
        ement: [],
        languages: [],
        company: { logo: '', name: '' },
        translations: {},
      };

      if (lastLoadedDataDate) {
        if (
          differenceInMinutes(parseISO(lastLoadedDataDate), new Date()) < -5
        ) {
          localStorage.setItem('lastUpdate', new Date().toISOString());
          response = await getData(selectedLanguage);
        } else if (localStorage.getItem('apiData')) {
          response = JSON.parse(localStorage.getItem('apiData') || '');
        }
      } else {
        localStorage.setItem('lastUpdate', new Date().toISOString());
        response = await getData(selectedLanguage);
      }

      setData(response);
    }

    getCategories();
  }, [language, setData]);

  return (
    <Container>
      <Settings onClick={handleLanguageClick}>
        <img src={activeLanguage?.flag} alt={activeLanguage?.name} />
      </Settings>
      <img src={logo} alt="Imenta" />
      <EmentCategoryList>
        {loading && (
          <ListLoading>
            <FiLoader size={30} color="#0029ff" />
            <p>A carregar a sua ementa</p>
          </ListLoading>
        )}
        {categories.map((categorie) => (
          <Link key={categorie.url} to={`/ement/${categorie.url}`}>
            <h2> {categorie.title} </h2>
            <p> {categorie.opcoes} </p>
          </Link>
        ))}
        <div>
          <p>
            {translations.label_iva
              ? translations.label_iva
              : 'IVA incluído à taxa legal em vigor '}
          </p>
          <span>
            Desenvolvido por
            <a
              href="http://www.imenta.pt"
              rel="noopener noreferrer"
              target="_blank"
            >
              iMenta.pt
            </a>
          </span>
        </div>
      </EmentCategoryList>
      <LanguagesContainer languagesActive={languagesActive}>
        <button type="button" onClick={handleLanguageClick} />
        <div>
          <CloseContainer onClick={handleLanguageClick}>
            <MdClose size={20} color="#0029ff" />
          </CloseContainer>
          <h2>
            {translations.label_languages
              ? translations.label_languages
              : 'Idiomas'}
          </h2>
          {languages.map((languageItem) => (
            <button
              type="button"
              onClick={() => handleChangeLocation(languageItem.id)}
            >
              <img src={languageItem.flag} alt="" />
              <p>{languageItem.name} </p>
            </button>
          ))}
        </div>
      </LanguagesContainer>
    </Container>
  );
};

export default HomePage;
