import styled from 'styled-components';

interface FilterProps {
  visible: boolean;
}

export const Container = styled.div`
  background-color: #fff;
  min-height: 100%;
  max-width: 1024px;
  margin: auto;
  padding-top: 60px;
`;

export const TitleContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  max-width: 1024px;
  margin: auto;
  z-index: 9;
`;

export const PageTitle = styled.div`
  height: 60px;
  padding: 0 7px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ededf2;
  z-index: 99;
  position: relative;

  button {
    outline: none;
    border: none;
    background: none;
  }

  h2 {
    font-family: Nobile;
    font-size: 16px;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
    text-align: center;
    margin: auto;
  }
`;

export const EmentLists = styled.div`
  z-index: 99;
  position: relative;
  display: none;
`;

export const EmentListActive = styled.button<FilterProps>`
  height: 58px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-shadow: 0px 1px 3px rgba(6, 4, 24, 0.1);
  width: 100%;
  border: none;
  outline: none;

  p {
    font-family: Nobile;
    font-weight: 500;
    font-size: 15px;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
  }

  div {
    background: rgba(0, 41, 255, 0.03);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    transform: ${(props) =>
      props.visible ? 'rotate(180deg);' : 'rotate(0deg)'};
    transition: all 0.3s;
  }
`;

export const AllEmentList = styled.div<FilterProps>`
  display: flex;
  flex-direction: column;
  padding: 0 15px 28px;
  background: #fff;
  transform: ${(props) => (props.visible ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  transition: height 0.1s ease, transform 0.3s ease;
  height: ${(props) => (props.visible ? 'auto' : '0')};
  box-shadow: 0px 1px 3px rgba(6, 4, 24, 0.1);
  overflow: hidden;
  max-height: 50vh;
  overflow: auto;

  a {
    font-family: Nobile;
    font-size: 15px;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
    text-decoration: none;
    margin-top: 23px;
  }
`;

export const BackgroundFilter = styled.div<FilterProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 4, 24, 0.8);
  z-index: 9;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: all 0.3s;
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
`;

export const EmentListItens = styled.div`
  height: 100%;

  > div {
    display: flex;
    align-items: self-start;
    padding: 15px;
    border-top: 1px solid #ededf2;
    &:first-child {
      border-top: 0;
    }
    img {
      max-width: 107px;
    }
  }

  @media (min-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > div {
      border-right: 1px solid #dadada;
      border-top: 1px solid #dadada;

      &:nth-child(-n + 2) {
        border-top: none;
      }
    }
  }

  @media (min-width: 1023px) {
    grid-template-columns: 1fr 1fr 1fr;

    > div {
      &:nth-child(-n + 2) {
        border-top: 1px solid #dadada;
      }
      &:nth-child(-n + 3) {
        border-top: none;
      }
    }
  }
`;

export const ProductInformation = styled.div`
  margin-left: 12px;
  width: 100%;

  h2 {
    font-family: Nobile;
    font-weight: 500;
    font-size: 14px;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
    margin-bottom: 8px;
    margin-top: 12px;
  }

  > p {
    font-family: Nobile;
    font-size: 12px;
    color: #818096;
    text-decoration: none solid rgb(129, 128, 150);
    line-height: 18px;
  }
`;

export const ProductinformationPrices = styled.div`
  margin-top: 15px;
  display: flex;

  div {
    flex: 1;
    p {
      font-family: Nobile;
      font-weight: 500;
      font-size: 14px;
      color: #0029ff;
      text-decoration: none solid rgb(0, 41, 255);
    }
    span {
      font-family: Nobile;
      font-size: 10px;
      color: #818096;
      text-decoration: none solid rgb(129, 128, 150);
    }
  }

  > p {
    font-family: Nobile;
    font-size: 12px;
    color: #818096;
    text-decoration: none solid rgb(129, 128, 150);
    text-align: right;
  }
`;

export const SubCategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px 0 15px;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 64px;
    text-decoration: none;
    font-family: Nobile;
    font-size: 16px;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
    line-height: 24px;
    border-bottom: 1px solid #ededf2;
  }

  @media (min-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;

    a:nth-child(-n + 2) {
      border-top: none;
    }
  }

  @media (min-width: 1023px) {
    grid-template-columns: 1fr 1fr 1fr;

    a:nth-child(-n + 2) {
      border-top: 1px solid #dadada;
    }
    a:nth-child(-n + 3) {
      border-top: none;
    }
  }
`;
