import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    outline: 0;
  }

  body{
    background: #060418;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root{
    height: 100%;
  }

  body, input, button{
    font-family: 'Nobile', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500;
  }

  button{
    cursor: pointer;
    background: none;
    border: none;
  }

  img{
    max-width: 100%;
  }

  [data-rmiz-overlay]{
    background-color: rgba(0, 0, 0, 0.85)!important;

    img{
      background-color: #fff;
    }
  }

  .is-drink{
    background:#f3f3f3
  }
  
`;
