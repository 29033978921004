import styled, { keyframes } from 'styled-components';

interface LanguageProps {
  languagesActive: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 1024px;
  margin: auto;
  position: relative;

  > img {
    margin: 95px 0 105px;
  }
`;

export const ListLoading = styled.div`
  grid-column: 1 / span 2;
  margin: auto;
  text-align: center;

  svg {
    animation: ${rotate} 2s linear infinite;
    margin-bottom: 10px;
  }

  p {
    font-family: Nobile;
    font-weight: 500;
    font-size: 15px;
    opacity: 0.5;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
    text-align: center;
  }

  @media (min-width: 767px) {
    grid-column: 1 / span 3;
  }
`;

export const EmentCategoryList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 184px;
  column-gap: 16px;
  row-gap: 12px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background: #ffffff;
  box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.05);
  padding: 40px 15px 9px;
  flex: 1;

  > a {
    height: 184px;
    border-radius: 16px;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    padding: 10px;
    overflow: hidden;
    overflow-wrap: anywhere;

    h2 {
      font-family: Nobile;
      font-size: 18px;
      color: #060418;
      text-decoration: none solid rgb(6, 4, 24);
      text-align: center;
      margin-bottom: 8px;
    }

    p {
      font-family: Nobile;
      font-size: 13px;
      color: #818096;
      text-decoration: none solid rgb(129, 128, 150);
      text-align: center;
    }
  }

  div {
    grid-column: span 2;
    text-align: center;
    font-family: Nobile;
    font-size: 11px;
    color: #726e91;
    text-decoration: none solid rgb(114, 110, 145);
    line-height: 18px;
    margin-top: 60px;

    span {
      font-family: Nobile;
      font-size: 11px;
      color: #726e91;
      text-decoration: none solid rgb(114, 110, 145);
      line-height: 18px;
      margin-top: 8px;
      display: block;

      a {
        /* iMenta.pt */
        font-family: Nobile;
        font-size: 11px;
        color: #726e91;
        margin-top: 8px;
        line-height: 18px;
        text-decoration: underline solid rgb(114, 110, 145);
        margin-left: 3px;
      }
    }
    @media (min-width: 767px) {
      grid-column: span 3;
    }
  }

  @media (min-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Settings = styled.button`
  position: absolute;
  top: 13px;
  right: 15px;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  /* background: rgba(255, 255, 255, 0.2); */
`;

export const LanguagesContainer = styled.div<LanguageProps>`
  position: fixed;
  top: 0;
  right: 0;
  left: ${(props) => (props.languagesActive ? '0' : '110%')};
  bottom: 0;
  background: rgba(6, 4, 24, 0.8);
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s;

  > div {
    background: #fff;
    width: 70%;
    height: 100%;
    max-width: 300px;

    > button {
      display: flex;
      align-items: center;
      height: 44px;
      font-family: Nobile;
      font-size: 16px;
      color: #060418;
      text-decoration: none solid rgb(6, 4, 24);
      line-height: 24px;
      font-weight: 500;
      width: 100%;
      padding-left: 15px;

      & + button {
        border-top: 1px solid #ededf2;
      }

      img {
        margin-right: 12px;
      }
    }
  }

  h2 {
    margin-top: 39px;
    font-family: Nobile;
    font-weight: bold;
    font-size: 22px;
    color: #060418;
    text-decoration: none solid rgb(6, 4, 24);
    line-height: 24px;
    margin-bottom: 15px;
    padding-left: 15px;
  }

  > button {
    width: 30%;
    flex: 1;
  }
`;

export const CloseContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgba(0, 41, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  margin-left: auto;
  margin-top: 15px;
`;
